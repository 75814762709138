import React, { Fragment, useContext, useEffect, useState } from 'react'
import {gql, useLazyQuery, useQuery} from '@apollo/client'
import { Row, Col, Dropdown } from 'react-bootstrap'
import { AuthContext } from '../context/auth-context'
import CustomToggle from '../layouts/components/CustomToggle'
import AnalyticsCard from '../components/campaigns/AnalyticsCard'
import ConversionCard from '../components/campaigns/ConversionCard'
import Card from '../components/common/Card'
import ProgressBar from '../components/common/ProgressBar'
import ConversionsChart from '../components/campaigns/ConversionsChart'
import CampaignCostChart from '../components/campaigns/CampaignCostChart'
import PublisherPlatformChart from '../components/campaigns/PublisherPlatformChart'
import ConversionRateBarChart from '../components/campaigns/ConversionRateBarChart'
import CampaignLeads from '../components/campaigns/CampaignLeads'
import { showNotification } from '../utils/notifications'
import { ToastContainer } from 'react-toastify'
import Loader from '../layouts/components/Loader'

// TODO: Replace it with new DB structure later
const GET_INSIGHTS = gql`
    query Publishers($where: PublisherCampaignsConnectionWhere, $publishersWhere2: PublisherWhere) {
        publishers(where: $publishersWhere2) {
            name
            campaignsConnection(where: $where) {
                edges {
                    impressions
                    reach
                    engagement
                    conversion
                    spend
                    costPerConversion
                    publishers
                    conversionsByMonths
                    conversionPerAds
                    salesPerAds
                    addToCart
                    addToCartValue
                    checkout
                    checkoutValue
                }
            }
        }
    }`

const GET_ARCHIVED_INSIGHTS = gql`
    query Publishers($publishersWhere: PublisherWhere, $adCampaignsWhere: AdCampaignWhere) {
        publishers(where: $publishersWhere) {
            name
            adCampaigns(where: $adCampaignsWhere) {
                addToCart
                addToCartValue
                conversion
                conversionsByMonths
                costPerConversion
                updatedAt
                engagement
                impressions
                publishers
                purchase
                purchaseValue
                reach
                spend
                landingPagesConnection {
                    edges {
                        sales
                        conversionRate
                        node {
                            id
                        }
                    }
                }
            }
        }
    }`

const GET_LANDING_PAGES = gql`
    query LandingPages($where: LandingPageWhere) {
        landingPages(where: $where) {
            id
            title
        }
    }`

const cartIcon = (
    <svg width="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M2 11.0786C2.05 13.4166 2.19 17.4156 2.21 17.8566C2.281 18.7996 2.642 19.7526 3.204 20.4246C3.986 21.3676 4.949 21.7886 6.292 21.7886C8.148 21.7986 10.194 21.7986 12.181 21.7986C14.176 21.7986 16.112 21.7986 17.747 21.7886C19.071 21.7886 20.064 21.3566 20.836 20.4246C21.398 19.7526 21.759 18.7896 21.81 17.8566C21.83 17.4856 21.93 13.1446 21.99 11.0786H2Z" fill="currentColor"></path>
        <path d="M11.2451 15.3843V16.6783C11.2451 17.0923 11.5811 17.4283 11.9951 17.4283C12.4091 17.4283 12.7451 17.0923 12.7451 16.6783V15.3843C12.7451 14.9703 12.4091 14.6343 11.9951 14.6343C11.5811 14.6343 11.2451 14.9703 11.2451 15.3843Z" fill="currentColor"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.211 14.5565C10.111 14.9195 9.762 15.1515 9.384 15.1015C6.833 14.7455 4.395 13.8405 2.337 12.4815C2.126 12.3435 2 12.1075 2 11.8555V8.38949C2 6.28949 3.712 4.58149 5.817 4.58149H7.784C7.972 3.12949 9.202 2.00049 10.704 2.00049H13.286C14.787 2.00049 16.018 3.12949 16.206 4.58149H18.183C20.282 4.58149 21.99 6.28949 21.99 8.38949V11.8555C21.99 12.1075 21.863 12.3425 21.654 12.4815C19.592 13.8465 17.144 14.7555 14.576 15.1105C14.541 15.1155 14.507 15.1175 14.473 15.1175C14.134 15.1175 13.831 14.8885 13.746 14.5525C13.544 13.7565 12.821 13.1995 11.99 13.1995C11.148 13.1995 10.433 13.7445 10.211 14.5565ZM13.286 3.50049H10.704C10.031 3.50049 9.469 3.96049 9.301 4.58149H14.688C14.52 3.96049 13.958 3.50049 13.286 3.50049Z" fill="currentColor"></path>
    </svg>
)

const checkoutIcon = (
    <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z" fill="currentColor"></path>
        <path opacity="0.4" d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z" fill="currentColor"></path>
        <circle cx="18" cy="11.8999" r="1" fill="currentColor"></circle>
    </svg>
)

const Campaign = () => {
    const { user } = useContext(AuthContext)
    const [data, setData] = useState({})
    const [status, setStatus] = useState('Active Campaign')

    const insights = useQuery(GET_INSIGHTS)
    const creatives = useQuery(GET_LANDING_PAGES)
    const [loadArchivedInsights, archivedInsights] = useLazyQuery(GET_ARCHIVED_INSIGHTS)

    const loadData = (status) => {
        setStatus(status === 'active' ? 'Active Campaign' : 'All Campaigns')
        if (status !== 'active') {
            loadArchivedInsights({
                variables: {
                    where: {
                        adCampaignsWhere: { status: "ARCHIVED" }
                    }
                },
                onCompleted: (data) => {
                    appendData(data)
                }
            })
        } else {
            if (insights.data?.publishers.length > 0) {
                const data = insights.data.publishers.find((publisher) => publisher.name === 'Meta').campaignsConnection.edges[0]
                setData(data)
            }
        }
    }

    const appendData = (archivedData) => {
        const combinedData = { ...data }
        combinedData.conversionsByMonths = updateConversionsByMonths(JSON.parse(combinedData.conversionsByMonths))
        combinedData.publishers = JSON.parse(combinedData.publishers)
        combinedData.salesPerAds = JSON.parse(combinedData.salesPerAds)
        combinedData.conversionPerAds = JSON.parse(combinedData.conversionPerAds)

        const newData = archivedData.publishers.find((publisher) => publisher.name === 'Meta')

        newData.adCampaigns.forEach((campaign) => {
            combinedData.engagement += campaign.engagement
            combinedData.conversion += campaign.conversion
            combinedData.impressions += campaign.impressions
            combinedData.reach += campaign.reach
            combinedData.spend += campaign.spend
            combinedData.costPerConversion += campaign.costPerConversion
            combinedData.addToCart += campaign.addToCart
            combinedData.addToCartValue += campaign.addToCartValue
            combinedData.checkout += campaign.purchase
            combinedData.checkoutValue += campaign.purchaseValue

            const newConversionsByMonths = updateConversionsByMonths(JSON.parse(campaign.conversionsByMonths), campaign.updatedAt)
            combinedData.conversionsByMonths = combinedData.conversionsByMonths.map((value, index) => value + newConversionsByMonths[index])

            const publishers = JSON.parse(campaign.publishers)
            Object.keys(publishers).forEach((key) => {
                if (combinedData.publishers[key] === undefined) combinedData.publishers[key] = 0
                combinedData.publishers[key] += publishers[key]
            })

            const salesPerAds = campaign.landingPagesConnection.edges.reduce((acc, edge) => {
                acc[edge.node.id] = edge.sales
                return acc
            }, {})
            Object.keys(salesPerAds).forEach((key) => {
                if (combinedData.salesPerAds[key] === undefined) combinedData.salesPerAds[key] = 0
                combinedData.salesPerAds[key] += salesPerAds[key]
            })

            const conversionPerAds = campaign.landingPagesConnection.edges.reduce((acc, edge) => {
                acc[edge.node.id] = edge.conversionRate
                return acc
            }, {})
            Object.keys(conversionPerAds).forEach((key) => {
                if (combinedData.conversionPerAds[key] === undefined) combinedData.conversionPerAds[key] = 0
                combinedData.conversionPerAds[key] += conversionPerAds[key]
            })
        })

        combinedData.costPerConversion = combinedData.costPerConversion / (newData.adCampaigns.length + 1)
        Object.keys(combinedData.conversionPerAds).forEach((key) => {
            combinedData.conversionPerAds[key] = combinedData.conversionPerAds[key] / (newData.adCampaigns.length + 1)
        })

        combinedData.conversionsByMonths = JSON.stringify(combinedData.conversionsByMonths)
        combinedData.publishers = JSON.stringify(combinedData.publishers)
        combinedData.salesPerAds = JSON.stringify(combinedData.salesPerAds)
        combinedData.conversionPerAds = JSON.stringify(combinedData.conversionPerAds)

        setData(combinedData)
    }

    const updateConversionsByMonths = (conversionsByMonths, updatedAt = null) => {
        const maxMonths = 12
        const updatedDate = updatedAt ? new Date(updatedAt) : new Date();
        const currentDate = new Date()

        // Calculate the number of months between the current date and the updated date
        const monthsDifference =
            (currentDate.getFullYear() - updatedDate.getFullYear()) * 12 +
            (currentDate.getMonth() - updatedDate.getMonth())

        // Shift the elements in the array by adding 0s for each month passed
        let updatedConversions = [...conversionsByMonths]

        // Add the required number of 0s based on the monthsDifference
        for (let i = 0; i < monthsDifference; i++) {
            updatedConversions.push(0)
        }

        // Ensure the array has exactly 12 elements by adding 0s to the left if necessary
        while (updatedConversions.length < maxMonths) {
            updatedConversions.unshift(0)
        }

        // If the array exceeds 12 elements, keep only the last 12
        if (updatedConversions.length > maxMonths) {
            updatedConversions = updatedConversions.slice(-maxMonths)
        }

        return updatedConversions
    }

    useEffect(() => {
        if (insights.data?.publishers.length > 0) {
            const data = insights.data.publishers.find((publisher) => publisher.name === 'Meta').campaignsConnection.edges[0]
            setData(data)
        }
    }, [insights.data])

    return (
        <Fragment>
            { archivedInsights.loading || insights.loading || creatives.loading || (Object.keys(data).length === 0 && data.constructor === Object) ? (
                <Loader />
            ) : (
                <Fragment>
                    <ToastContainer/>
                    <div className="mb-5">
                        <div className="d-flex flex-wrap justify-content-between mb-4">
                            <h3 className="mb-0">Campaign Summary</h3>
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} variant=" text-secondary dropdown-toggle">
                                    {status}
                                </Dropdown.Toggle>
                                <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
                                    <li><Dropdown.Item href="#" onClick={() => loadData('active')}>Active Campaign</Dropdown.Item></li>
                                    <li><Dropdown.Item href="#" onClick={() => loadData('all')}>All Campaigns</Dropdown.Item></li>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <Row>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? 862 : data.engagement} content="Audience Engagement" color="#08B1BA" />
                            </Col>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? '76%' : data.conversion} content="Audience Conversion" color="#427EEB" />
                            </Col>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? 1024 : data.impressions} content="Impressions" color="#08B1BA" />
                            </Col>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? '4%' : data.reach} content="Reach" color="#427EEB" />
                            </Col>
                        </Row>

                        <Row>
                            <ConversionsChart data={data} />

                            <Col lg="3" className="mt-0 mt-sm-md-0">
                                <Card>
                                    <Card.Header>
                                        <h4 className="mb-0">Cost and Efficiency</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col sm="12">
                                                <CampaignCostChart data={data} />
                                            </Col>
                                        </Row>
                                        <div className="d-flex mt-3 mt-sm-0">
                                            <span className="avatar-50 bg-soft-info rounded">
                                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" height="32">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z" fill="currentColor"></path>
                                                    <path opacity="0.4" d="M16.9739 6.77432C16.977 6.85189 16.9621 6.92913 16.9303 7H15.4932C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H7.00989C6.9967 6.92535 6.9967 6.84898 7.00989 6.77432C7.12172 4.10591 9.32499 2 12.0049 2C14.6849 2 16.8882 4.10591 17 6.77432H16.9739Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <div className="w-100 ms-3">
                                                <div className="d-flex justify-content-between">
                                                    <h6>Conversion Cost</h6>
                                                    <p>${process.env.REACT_APP_DEMO_USER_ID.includes(user.id)
                                                        ? 8.55
                                                        : (data.costPerConversion ? data.costPerConversion.toFixed(2) : 0)}</p>
                                                </div>
                                                <ProgressBar
                                                    softcolors="info"
                                                    color="info"
                                                    className="shadow-none w-100"
                                                    value={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) || data.costPerConversion ? 45 : 0}
                                                    minvalue={0}
                                                    maxvalue={100}
                                                    style={{height: "8px"}}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg="4">
                                <ConversionCard
                                    svg={cartIcon}
                                    number={data.addToCart}
                                    value={data.addToCartValue}
                                    rate={58}
                                    colour="bg-soft-info"
                                    progress="info"
                                    label="Add to Cart"
                                />
                                <ConversionCard
                                    svg={checkoutIcon}
                                    number={data.checkout}
                                    value={data.checkoutValue}
                                    rate={40}
                                    colour="bg-soft-primary"
                                    progress="primary"
                                    label="Purchase"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <PublisherPlatformChart name={'Publisher Platforms'} data={data}/>
                            <ConversionRateBarChart name={'Creative Conversion Rate'} data={data} colour="#427EEB" creatives={creatives.data.landingPages} rates={true}/>
                            <ConversionRateBarChart name={'Sales by Creative'} data={data} colour="#08B1BA" creatives={creatives.data.landingPages} rates={false}/>
                        </Row>

                        {/*<Col md={12}>*/}
                            {/*<CampaignLeads />*/}
                        {/*</Col>*/}

                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Campaign